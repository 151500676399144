import React, { useEffect } from 'react';
import {
    Box,
    SimpleGrid,
    Text,
    useColorModeValue,
    Image,
    Button,
} from "@chakra-ui/react"

const Project = () => {
    useEffect(() => {
        document.title = "Projek"
    }, []);

    const projectData = [
        {
            img: process.env.PUBLIC_URL + '/img/project1.png',
            title: 'Personal Portfolio',
            description: 'Sebuah website yang saya buat untuk menapilkan Portfolio saya, beserta data diri termasuk cara untuk menghubungi saya',
            linkFirstButton: null,
            buttonText: 'Github',
            icon: 'fa-brands fa-github fa-lg',
            linkText: 'Selengkapnya',
            iconLink: 'fa-solid fa-link',
            link: 'https://micinproject.my.id',
        },
    ]

    return(
        <Box pt={["2rem", "4rem"]} pb={['9rem', '8rem', '9rem', '7rem']}>
            <Text fontSize="6xl" as="h1" fontWeight="Bold" color={useColorModeValue("lightYellow", "yellowNeon")}>Project</Text>
            <Text as="h3" fontSize="xl">Beberapa Projek yang telah saya kerjakan</Text>
                <Box>
                    {projectData.map((project) => (
                        <SimpleGrid columns={[1,2]} spacing={['10px', '10px', '40px', '40px']} mt={10} mb={6}>
                            <Box>
                                <a href={project.link} target='blank'>
                                    <Image
                                        src={project.img}
                                        borderRadius='15px'
                                        border='2px solid indigo'
                                    >
                                    </Image>
                                </a>
                            </Box>
                            <Box>
                                <Text 
                                    as='a' 
                                    fontWeight='bold' 
                                    fontSize='25px'
                                    _hover={{ textDecoration:'underline' }}
                                    cursor='pointer'
                                >
                                    <a href={project.link} target='blank'>
                                        {project.title}
                                    </a>
                                </Text>
                                <Text as='p'>{project.description}</Text>
                                <SimpleGrid
                                    column={[1, 1, 2, 2]}
                                    spacing={3}
                                    display='block'
                                >
                                    <Button 
                                        size='lg' 
                                        bg='naturalYellow' 
                                        _hover={{ bg: 'yellowNeon' }} 
                                        color='black'
                                        mr={3}
                                        my={3}
                                    >
                                        <a href={project.linkFirstButton} target='blank'>
                                            <i class={project.icon} style={{paddingRight: '5px'}}></i>
                                            {project.buttonText}
                                        </a>
                                    </Button>
                                    <Button 
                                        size='lg' 
                                        bg='black' 
                                        _hover={{ bg: 'blackAlpha.800', color: 'whiteAlpha.700' }} 
                                        color='white'
                                        border='2px solid indigo'
                                    >
                                        <a href={project.link} target='blank' fontWeight='light'>
                                            <i class={project.iconLink} style={{paddingRight: '5px'}}></i>
                                            {project.linkText}
                                        </a>
                                    </Button>
                                </SimpleGrid>
                            </Box>
                        </SimpleGrid> 
                    ))}
                </Box>
        </Box>
    )
}

export default Project;