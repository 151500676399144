import React, { useEffect } from 'react';
import {
    Box,
    Text,
    useColorModeValue,
} from "@chakra-ui/react"

// import pdf from "./cv.pdf"

const About = () => {
    useEffect(() => {
        document.title = "Tentang Saya"
    }, []);
    return(
        <Box pt={["2rem", "2rem", "2rem", "4rem"]} pb={['9rem', '8rem', '9rem', '9rem']}>
            <Text fontSize="6xl" as="h1" fontWeight="Bold" color={useColorModeValue("lightYellow", "yellowNeon")}>About</Text>   
            <Box
                 mt={0}
            >
                <Text fontSize={["lg", "md"]} maxW={["100%", "80%"]}>
                    Saya Meizaluna Wulandari biasa dipanggil Luna, Saya tertarik dengan dunia teknologi khususnya dalam bidang Pengembangan Web dan Pengelolaan Jaringan.
                </Text>
                <Text fontSize={["lg", "md"]} maxW={["100%", "80%"]} mt={1}>
                    Saya senang terlibat dalam sebuah komunitas IT di Internet, saya senang untuk berbagi pengetahuan yang saya ketahui di forum-forum komunitas sayang saya ikuti. Saya percaya dengan tetap membantu orang yang kesusahan dalam belajar teknologi secara perlahan juga akan meningkatkan memampuan saya.
                </Text>
            </Box>
            {/*<Button 
                mt={5} 
                size="lg" 
                bg={useColorModeValue("naturalYellow", "yellowNeon")}
                color="indigo"
            >
                <a href={pdf} target="blank">Resume</a>
            </Button>*/}
        </Box>
    )
}

export default About;