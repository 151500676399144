import React, { useEffect } from 'react';
import {
    Box,
    Text,
    useColorModeValue,
    Stack,
    Button,
} from "@chakra-ui/react"
import { Link } from 'react-router-dom';


const Home = () => {
    useEffect(() => {
        document.title = "Beranda"
    }, []);
    return(
        <Box pt={["2rem", "5rem"]} pb={['9rem', '8rem', '9rem', '7rem']}>
            <Text fontSize="6xl" as="h1" fontWeight="Bold" color={useColorModeValue("lightYellow", "yellowNeon")}>Saya Meizaluna Wulandari</Text>
            <Box>
                <Text fontSize="3xl" fontWeight="semibold" color={useColorModeValue("naturalYellow", "gray.500")}>Web Developer & Network Administrator</Text>
            </Box>
            <Box
                py="10px"
            >
                <Text fontSize={["lg", "md"]} maxW={["100%", "80%"]}>
                    Saya Meizaluna Wulandari biasa dipanggil Luna, Saya tertarik dengan dunia teknologi khususnya dalam bidang Pengembangan Web dan Pengelolaan Jaringan.
                    Saya mulai mempelajari tentang Pengembangan Web sejak awal masuk SMK, hingga saat ini saya telah berbagai Bahasa Pemerograman untuk Web seperti PHP, JavaScript. Selain itu saya juga menguasai Framework seperti CodeIgniter, Laravel, JQuery, Alpine
                    dan saat ini saya sedang belajar Bahasa Pemerograman Go. 
                </Text>
                <Text fontSize={["lg", "md"]} maxW={["100%", "80%"]}>
                    Dalam Jaringan sendiri saya memiliki sedikit pengalaman ketika melaksanakan Praktek Kerja Lapangan di CV. Twincom Group. 
                    Saat melaksanakan PKL di CV. Twincom Group dalam saya belajar banyak seperti Instalasi CCTV, Instalasi IP Cam, Instalasi Sistem Absensi berbasis Fingerprint baik Hardware maupun softwarenya.
                    Selain Instalasi Jaringan ketika PKL saya juga diajarkan mengenai perbaikkan Alat Elektonik seperti Laptop, Komputer, dan Printer.
                </Text>
            </Box>
            <Box
            display="flex"
            flexBasis={{ base: "100%", md: "auto" }}
            my="11.5px"
            >
            <Stack
                spacing={6}
                align={["baseline", "center", "center", "center"]}
                justify={["start", "space-between", "flex-end", "flex-end"]}
                direction={["column", "row"]}
            >
                <Button 
                    bg={useColorModeValue("indigo", "yellowNeon")}
                    size="lg"
                    color={useColorModeValue("white", "indigo")}
                    fontSize={16}
                    padding={6}
                    borderRadius="7px"
                    _hover={{ bg: (useColorModeValue("lightIndigo", "lightYellow")) }}
                >
                    <Link to='contact'>Hubungi Saya</Link>
                </Button>
                <Button
                    bg={useColorModeValue("indigo", "yellowNeon")}
                    size="lg"
                    color={useColorModeValue("white", "indigo")}
                    fontSize={16}
                    padding={6}
                    borderRadius="7px"
                    _hover={{ bg: (useColorModeValue("lightIndigo", "lightYellow")) }}
                >
                    <Link to='project'>Projek Saya</Link>
                </Button>   
            </Stack>
            </Box>
        </Box>
    )
}

export default Home;
