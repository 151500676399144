import React, { useEffect } from 'react';
import {
    Box,
    SimpleGrid,
    Text,
    useColorModeValue,
} from "@chakra-ui/react"

const Skills = () => {
    useEffect(() => {
        document.title = "Keahlian"
    }, []);
    return(
        <Box pt={["2rem", "2.5rem"]} pb={['9rem', '8rem', '9rem', '8rem']}>
            <Text fontSize="6xl" as="h1" fontWeight="Bold" color={useColorModeValue("indigo", "yellowNeon")}>Keahlian</Text>
            <Box>
                <SimpleGrid 
                    mt={5}
                    mb={3}
                    columns={[1, 2, 4]}
                    spacing={8}
                >
                    <Box
                        bg={useColorModeValue('naturalYellow', 'black')}
                        color={useColorModeValue('indigo', 'yellowNeon')}
                        padding={7}
                        borderRadius='11px'
                    >
                        <span class="iconify" data-icon="bxl:html5" data-width="26" data-height="26"></span>
                        <Text fontSize='xl' fontWeight='medium' as='h1' mt={2}>HTML</Text>
                    </Box>
                    <Box
                        bg={useColorModeValue('naturalYellow', 'black')}
                        color={useColorModeValue('indigo', 'yellowNeon')}
                        padding={7}
                        borderRadius='11px'
                    >
                        <span class="iconify" data-icon="ci:css3" data-width="26" data-height="26"></span>
                        <Text fontSize='xl' fontWeight='medium' as='h1' mt={2}>CSS</Text>
                    </Box>
                    <Box
                        bg={useColorModeValue('naturalYellow', 'black')}
                        color={useColorModeValue('indigo', 'yellowNeon')}
                        padding={7}
                        borderRadius='11px'
                    >
                        <span class="iconify" data-icon="akar-icons:bootstrap-fill" data-width="26" data-height="26"></span>
                        <Text fontSize='xl' fontWeight='medium' as='h1' mt={2}>Bootstrap</Text>
                    </Box>
                    <Box
                        bg={useColorModeValue('naturalYellow', 'black')}
                        color={useColorModeValue('indigo', 'yellowNeon')}
                        padding={7}
                        borderRadius='11px'
                    >
                        <span class="iconify" data-icon="bxl:tailwind-css" data-width="26" data-height="26"></span>
                        <Text fontSize='xl' fontWeight='medium' as='h1' mt={2}>Tailwind</Text>
                    </Box>
                    <Box
                        bg={useColorModeValue('naturalYellow', 'black')}
                        color={useColorModeValue('indigo', 'yellowNeon')}
                        padding={7}
                        borderRadius='11px'
                    >
                        <span class="iconify" data-icon="akar-icons:javascript-fill" data-width="26" data-height="26"></span>
                        <Text fontSize='xl' fontWeight='medium' as='h1' mt={2}>JavaScript</Text>
                    </Box>
                    <Box
                        bg={useColorModeValue('naturalYellow', 'black')}
                        color={useColorModeValue('indigo', 'yellowNeon')}
                        padding={7}
                        borderRadius='11px'
                    >
                        <span class="iconify" data-icon="mdi:jquery" data-width="26" data-height="26"></span>
                        <Text fontSize='xl' fontWeight='medium' as='h1' mt={2}>jQuery</Text>
                    </Box>
                    <Box
                        bg={useColorModeValue('naturalYellow', 'black')}
                        color={useColorModeValue('indigo', 'yellowNeon')}
                        padding={7}
                        borderRadius='11px'
                    >
                        <span class="iconify" data-icon="logos:alpinejs-icon" data-width="26" data-height="26"></span>
                        <Text fontSize='xl' fontWeight='medium' as='h1' mt={2}>Alpine JS</Text>
                    </Box>
                    <Box
                        bg={useColorModeValue('naturalYellow', 'black')}
                        color={useColorModeValue('indigo', 'yellowNeon')}
                        padding={7}
                        borderRadius='11px'
                    >
                        <span class="iconify" data-icon="simple-icons:php" data-width="26" data-height="26"></span>
                        <Text fontSize='xl' fontWeight='medium' as='h1' mt={2}>PHP</Text>
                    </Box>
                    <Box
                        bg={useColorModeValue('naturalYellow', 'black')}
                        color={useColorModeValue('indigo', 'yellowNeon')}
                        padding={7}
                        borderRadius='11px'
                    >
                        <span class="iconify" data-icon="mdi:laravel" data-width="26" data-height="26"></span>
                        <Text fontSize='xl' fontWeight='medium' as='h1' mt={2}>Laravel</Text>
                    </Box>
                    <Box
                        bg={useColorModeValue('naturalYellow', 'black')}
                        color={useColorModeValue('indigo', 'yellowNeon')}
                        padding={7}
                        borderRadius='11px'
                    >
                        <span class="iconify" data-icon="simple-icons:codeigniter" data-width="26" data-height="26"></span>
                        <Text fontSize='xl' fontWeight='medium' as='h1' mt={2}>CodeIgniter</Text>
                    </Box>
                    <Box
                        bg={useColorModeValue('naturalYellow', 'black')}
                        color={useColorModeValue('indigo', 'yellowNeon')}
                        padding={7}
                        borderRadius='11px'
                    >
                        <span class="iconify" data-icon="arcticons:mikrotik" data-width="26" data-height="26"></span>
                        <Text fontSize='xl' fontWeight='medium' as='h1' mt={2}>Mikrotik</Text>
                    </Box>
                    <Box
                        bg={useColorModeValue('naturalYellow', 'black')}
                        color={useColorModeValue('indigo', 'yellowNeon')}
                        padding={7}
                        borderRadius='11px'
                    >
                        <span class="iconify" data-icon="fe:git" data-width="26" data-height="26"></span>
                        <Text fontSize='xl' fontWeight='medium' as='h1' mt={2}>Git</Text>
                    </Box>
                    <Box
                        bg={useColorModeValue('naturalYellow', 'black')}
                        color={useColorModeValue('indigo', 'yellowNeon')}
                        padding={7}
                        borderRadius='11px'
                    >
                        <span class="iconify" data-icon="akar-icons:github-fill" data-width="26" data-height="26"></span>
                        <Text fontSize='xl' fontWeight='medium' as='h1' mt={2}>Github</Text>
                    </Box>
                </SimpleGrid>
            </Box>
        </Box>
    )
}

export default Skills;