import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools';

// 2. Add your color mode config
const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

// 3. extend the theme
const theme = extendTheme({ 
    config, 
    colors: {
      indigo: "#020617",
      yellowNeon: "#63e6fd",
      naturalYellow: "#038eb7",
      darkWhite: "#efefef",
      lightIndigo: "#54ded0",
      lightYellow: "#00b9e1",
      black: "#11092a",
      kaca: "RGBA(0, 0, 0, 0.5)",
    },
    styles: {
      global: (props) => ({
        body: {
          bg: mode("darkWhite", "indigo")(props),
        },
      }),
    },
})

export default theme;